<template>
  <vue100vh class="interactiveSection mapSection">

    <video 
      v-if="currentSection && currentSection.type === 'video'"
      width="100%"
      height="100%" 
      ref="thePano" 
      id="videojs-vr-player" 
      class="video-bg video-js vjs-default-skin" v-bind:class="videoReady ? 'videoReady' : ''" 
      controls playsInline>
      <!-- <source :src="'/video/h265/' + currentSection.videoName + '.mp4' + videoVersion" type='video/mp4;codecs="hevc,hvc1"'> -->
      <source :src="'/video/' + currentSection.videoName + '_h265.mp4' + videoVersion" type='video/mp4;codecs="hevc,hvc1"'>
      <source :src="'/video/' + currentSection.videoName + '.webm' + videoVersion" type='video/webm;codecs="vp9,opus"'>
      <source :src="'/video/' + currentSection.videoName + '.mp4' + videoVersion" type='video/mp4'>
    </video>

    <div v-if="currentSection && currentSection.type === 'video'" class="spinLoader" v-bind:class="videoReady ? 'videoReady' : ''">
      <div class="inner">
        <h5 v-if="!videoReady">Loading...</h5>
      </div>
    </div>
    <div class="tiltMe" @click="toggleFullScreen">
      <div class="inner">
        <NavIcon :icon="currentSection.section" />
        <h4>{{currentSection.title}} <span>360&deg; Experience</span></h4>
        <img src="images/tiltMe.svg">
      </div>
    </div>
    <div class="infoSection" v-bind:class="[showInfo ? '':'closed' , this.$parent.showTutorial ? 'showTutorial':'']" v-if="currentSection">
        <div class="infoBackground">
          <transition name="fade">
            <DetailContent class="content" v-if="showInfo" v-bind:sectionName="targ" />
          </transition>
        </div>
        <div class="infoHeader" @click="toggleInfo">
          <h4>{{currentSection.title}}</h4>
        </div>
        <img class="tutorial" src="images/tutorial.svg">
    </div>
   
  </vue100vh>
</template>

<script>
// @ is an alias to /src
import DetailContent from '@/components/details.vue'
import NavIcon from '@/components/navIcons.vue'
import vue100vh from 'vue-100vh'


export default {
  name: 'mapSection',
  props: ['slug','targ'],
  components: {
    DetailContent, NavIcon, vue100vh
  },
  data(){
    return {
        currentSection: null,
        videoReady: false,
        firstPlay: false,
        showInfo: false,
        pano: null,
        panoViewer: null,
        videoVersion: '?v=1.3'
    }
  },
  created(){
        let allData = this.$parent.mapSections;
        let thisSec = allData.filter(sec => sec.section === this.targ);
        this.currentSection = thisSec[0];
  },
  mounted(){
      let that = this
      const vjsOptions = {
        autoplay: 'play',
        loop: true,
        controlBar: {
          progressControl: true,
          RemainingTimeDisplay: false,
          PictureInPictureToggle: false,
          fullscreenToggle: false,
        },
        inactivityTimeout: 0,
        // sources:[
        //   {src: '/video/h265/' + this.currentSection.videoName + '.mp4' + this.videoVersion, type: 'video/mp4;codecs="hevc"'},
        //   {src: '/video/' + this.currentSection.videoName + '.webm' + this.videoVersion, type: 'video/webm;codecs="vp9,opus"'},
        //   {src: '/video/' + this.currentSection.videoName + '.mp4' + this.videoVersion, type: 'video/mp4'},
        // ],
      }
      this.pano = videojs('videojs-vr-player', vjsOptions)
      this.pano.vr({
        projection: '360',
        // forceCardboard: true, 
        motionControls: true
      })
      
      this.pano.on('canplay', that.onReady)
      this.pano.on('pause', that.onPause)
      this.pano.on('play', that.onPlay)
      this.pano.on('error', that.onError)

      this.$ua.trackView(this.currentSection.title, true)
    },
  beforeDestroy(){
    if (this.pano) {
            this.pano.dispose()
        }
  },
  methods: {
        onError(){
          var err = this.pano.error()
          var msg = 'Error: ' + err.code + ' - ' +err.message + ' ';
          var fi = 'File: src = ' + this.pano.currentSrc() + ' Type = ' + this.pano.currentType()
          this.$ua.trackEvent(this.currentSection.title, 'Error', msg + fi);
          console.log(msg)
          console.log(fi)
        },
        onReady(){
            this.videoReady = true
            this.pano.play()
            console.log('Ready: src = ' + this.pano.currentSrc() + ' type = ' + this.pano.currentType())
        },
        onPlay: function(){
          this.$parent.videoIsPlaying = true
        },
        onPause: function(){
          this.$parent.videoIsPlaying = false
        },
        toggleInfo: function(){
            this.$parent.showTutorial = false;
            if(!this.showInfo){
              this.$ua.trackView(this.currentSection.title + 'details', true);
            }
            this.showInfo = !this.showInfo;
            this.$parent.showTutorial = false;
            if(this.currentSection.type === 'video'){
              if(this.showInfo){
                  this.pano.pause();
              } else {
                  this.pano.play();
              }
            }
        },
        playVideo: function(){
          if(this.currentSection.type === 'video' && !this.showInfo){
            this.pano.playVideo()
          }
        },
        pauseVideo: function(){
          if(this.currentSection.type === 'video' && !this.showInfo){
            this.pano.pauseVideo()
          }
        },
        unmuteAudio: function(){
          if(this.currentSection.type === 'video' && !this.showInfo){
            this.pano.muted(false)
          }
        },
        muteAudio: function(){
          if(this.currentSection.type === 'video' && !this.showInfo){
            this.pano.muted(true)
          }
        },
        toggleFullScreen: function () {
          var doc = window.document;
          var docEl = doc.documentElement;

          var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
          var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

          if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
            requestFullScreen.call(docEl);
          }
          else {
            cancelFullScreen.call(doc);
          }
        }
  },
  
  watch: {
      // showInfo: function(){
          
      // }
  }
}
</script>
