<template>
  <div >

    <section v-if="sectionName === 'camp'" >
        <h4>Camping</h4>
        <p>There are more than 2,000 campsites in the Boundary Waters. Each one offers something different, but all Boundary Waters campsites have a campfire pit, cooking grate, and latrine—a fancy French word for an outdoor toilet. You may camp up to fourteen 14 consecutive days at a single campsite.</p>
        <p>All designated campsites within the Boundary Waters can be identified by the U.S. Forest Service-supplied steel fire grates. There may be campfire restrictions during your visit. Check the weather and local Ranger Station before your trip. Campfires are essential to any campsite—and good ghost story—although you may want to bring a small camp stove for cooking in case of rain.</p>
        <div class="cols col3">
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/campsite/camping_05.jpg`"></div>
            </div>
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/campsite/camping_06.jpg`"></div>
            </div>
        </div>
        <h4>Campfires</h4>
        <p>You’ll need to collect firewood by taking short hikes or a paddle away from your campsite to find down and dead trees and branches—trees and branches that are already on the ground and are dried out. Cutting down a tree in the Boundary Waters Canoe Area Wilderness is highly regulated.</p>
        <p>When leaving your campsite or going to sleep, make sure you put out your fire with water to prevent the possibility of a forest fire starting. Stir the ashes until they are cold to the touch with a bare hand.</p>
        <h4>Leave No Trace</h4>
        <p>The backcountry ethic, “leave no trace,” is the law of the land in the Boundary Waters. This means whatever you carry into the Wilderness, you carry out. Cans and glass bottles are not allowed, however, containers of fuel, insect repellent, and medicines are. “Leave no trace” also applies to bodily functions as well: the Boundary Waters offers two unique restroom opportunities. The first is using a latrine toilet at a designated campsite. The second method is to dig a small hole 6” deepat least 150 feet away from the shoreline, and burying your own excrement. Pro-tip: make sure to mark your bathroom spot with a twig or two as to not accidentally dig up the same spot twice.</p>
    </section>

    <section v-if="sectionName === 'paddle'" >
        <h4>Welcome</h4>
        <p>The Boundary Waters Canoe Area Wilderness is America’s most visited wilderness area. It contains 1.1 million acres of pristine water and unspoiled woodlands. Along with the Superior National Forest, it contains 20 percent of all the freshwater in the entire National Forest System.</p>
        <p>There are over 1,100 lakes in the Boundary Waters Canoe Area Wilderness and over 1,200 miles of canoe routes to paddle and explore.</p>
        <div class="cols col3">
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/paddle/paddle_05.jpg`"></div>
            </div>
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/paddle/paddle_06.jpg`"></div>
            </div>
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/paddle/paddle_07.jpg`"></div>
            </div>
        </div>
        <h4>Entering the BWCA</h4>
        <p>In order to enter the Boundary Waters Canoe Area (BWCA), you must obtain a government-issued permit for overnight trips, or a self-issued permit for day trips. You must enter at your chosen entry point on the date of your permit. Groups are limited to no more than nine persons and four canoes per party.</p>
        <p>While the Boundary Waters has over 150,000 overnight visitors a year, no one currently lives in the Wilderness. You won’t find any roads or buildings, and once you enter it, you officially leave civilization and enter into a world where it’s just you and the great outdoors. </p>
        <div class="cols col3">
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/paddle/paddle_02.jpg`"></div>
            </div>
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/paddle/paddle_01.jpg`"></div>
            </div>
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/paddle/paddle_04.jpg`"></div>
            </div>
        </div>
        <p>Motorboats are banned within the Boundary Waters except in a few periphery lakes. In fact, any device with a rotating motor of any kind is strictly prohibited. This type of regulation protects the Wilderness from sound pollution in an attempt to maintain the soundscape  as it had been for thousands of years.</p>
        <p>In the winter, Boundary Waters visitors swap canoes for snowshoes, dogsleds, and nordic skis for a completely different take on this one-of-a-kind Wilderness.</p>
    </section>

    <section v-if="sectionName === 'leave'" >
        <h4>Protecting the legacy of wilderness</h4>
        <p>In 1909, the Superior National Forest was established and the Boundary Waters Treaty was signed by Canada and the United States, requiring that neither country pollute Boundary waters or waters that flow across the border. However, it wouldn’t be until 1978 that the Boundary Waters Wilderness Act is passed, banning mining within the Wilderness, establishing a 220,000-acre Mining Protection Zone along entry corridors, and further protecting the BWCAW.</p>
        <p>Not only is the Boundary Waters a crown jewel of America’s public lands but it also remains the economic lifeblood of northeastern Minnesota's lucrative tourism industry. Tourism generates $913 million in revenue per year in economic benefits and creates 17,000 jobs that support local families and businesses. However, this special place is threatened by sulfide-ore copper mining. </p>
        <div class="cols col3">
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/packout/packout_05.jpg`"></div>
            </div>
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/packout/packout_06.jpg`"></div>
            </div>
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/packout/packout_07.jpg`"></div>
            </div>
        </div>
        <h4>The Threat of Mining</h4>
        <p>The proposed sulfide-ore copper mine on the edge of the Boundary Waters is the first step toward an industrial corridor of mines, mills, roads, rail lines and toxic tailing piles at the edge of the Boundary Waters. A single copper mine in this watershed will continuously pollute the wilderness for at least 500 years.</p>
        <p>Pollution from the proposed copper mines would flow directly into the heart of the Boundary Waters. Even conservative models of pollution show that waterways would carry contaminants into the Wilderness.</p>
        <p>Sulfide-ore copper mining is much more toxic than Minnesota’s taconite and iron-ore mining. It produces giant waste piles that, when exposed to air and water, leach sulfuric acid, heavy metals and sulfates. Sulfide-ore copper mines pollute groundwater, rivers and lakes.</p>
        <p>Acid mine drainage, heavy metals and associated pollutants from sulfide-ore copper mines harm microorganisms, aquatic plants and fish. Acid mine drainage also increases the acidity of water. As acidity increases, we know certain species will be unable to survive. Minnows are impacted first, followed by walleye, northerns, smallmouth bass, trout and loons.</p>
    </section>

    <section v-if="sectionName === 'fish'" >
        <h4>Fishing</h4>
        <p>The Boundary Waters is a world-class fishing destination. Going on a fishing trip is not only a great way to experience the Wilderness, but also a great way to enjoy the deliciousness of a campfire-cooked fish dinner.</p>
        <div class="cols col3">
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/animals/animals_03.jpg`"></div>
            </div>
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/animals/animals_04.jpg`"></div>
            </div>
        </div>
        <p>There are four major fish species you will find in the Boundary Waters: Northern pike, smallmouth bass, walleye, &amp; trout.</p>
        <h4>Wildlife</h4>
        <div class="cols col3">
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/animals/animals_01.jpg`"></div>
            </div>
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/animals/animals_02.jpg`"></div>
            </div>
        </div>
        <p>The wild waters and forests of the Boundary Waters provide critical habitat for wildlife, including several endangered and threatened species such as Canada lynx and moose.</p>
        <p>The Common Loon—and <em>not</em> the blood-thirsty mosquito—is the state bird of Minnesota and is abundant throughout the Wilderness.</p>
        <p>Loons begin to migrate south in September, but you’re likely to hear them laughing through summer evenings and into fall. Also, listen for moose bellowing: They begin mating in early fall.</p>
        <p>Black bears and timber wolves also call the Boundary Waters home. This is why it is especially important to hang your foodpack between two trees or bring a bear-proof container to ensure your food isn’t eaten by a hungry animal, cutting your trip too short. </p>
        <p class="info">Photos by Kevin Crosby, Kevin Noble, Hunter Brumels &amp; John Sekutowski from Unsplash</p>
    </section>

    <section v-if="sectionName === 'hike'" >
        <h4>Hiking</h4>
        <p>While most people think of the Boundary Waters as a paddling enthusiast’s haven, adventure-seekers of every kind visit this enchanting Wilderness for a plethora of outdoor activities that don’t involve paddling. One such activity is hiking.</p>
        <div class="cols col3">
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/hiking/hiking_01.jpg`"></div>
            </div>
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/hiking/hiking_02.jpg`"></div>
            </div>
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/hiking/hiking_05.jpg`"></div>
            </div>
        </div>
        <p>The Boundary Waters has over 230 miles of overnight hiking trails just waiting for exploration, including the:
            <ul>
                <li>Pow Wow Trail</li>
                <li>Sioux-Hustler Trail</li>
                <li>Snowbank Lake loops</li>
                <li>Kekekabic Trail</li>
                <li>Border Route &amp; associated side trails</li>
                <li>Brule Lake &amp; Eagle Mountain Trails</li>
                <li>Angleworm Trail</li>
            </ul>
        </p>
        <p>Hiking in the Boundary Waters allows you to explore places that are inaccessible by water and offers opportunities to see different landscapes and wildlife.</p>
        <p>Permits are required year-round for hiking in the Boundary Waters and require the use and knowledge of land navigation by a compass and map.</p>
    </section>

    <section v-if="sectionName === 'pictographs'" >
        <h4>Pictographs</h4>
        <p>The Boundary Waters is home to many archaeological treasures. However, among the most sacred of its treasures are the pictographs, believed to be created by the ancestors of the Ojibwe people. (The Ojibwe are part of Algonkian—a group of North American native peoples who share a language. The Ojibwe are the largest in this group and live across northern Minnesota.)</p>
        <div class="cols col3">
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/pictographs/pictographs_01.jpg`"></div>
            </div>
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/pictographs/pictographs_02.jpg`"></div>
            </div>
        </div>
        <p>Pictographs, simply put, are symbols that depict a story. In the Boundary Waters, these works of art are part of this one-of-a-kind Wilderness that tell the story of those who have inhabited and protected this special place.</p>
        <p>No one knows exactly when the pictographs were painted. However, they are estimated to be between 400-500 years old. There are also estimated to be over 400 pictographs throughout the Boundary Waters Canoe Area Wilderness and Canada’s Quetico Provincial Park.</p>
        <p>Can you spot the pictographs seen here? These iconic markings are found on North Hegman lake.They are possibly the most well-known in the Boundary Waters, and consist of a human-like figure, a bull moose, a long-tailed quadruped, three canoes, various lines and a cross. </p>
        <div class="cols col3">
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/pictographs/pictographs_03.jpg`"></div>
            </div>
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/pictographs/pictographs_05.jpg`"></div>
            </div>
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/pictographs/pictographs_06.jpg`"></div>
            </div>
        </div>
        <p>If you get a chance to marvel at these pictographs please remember to respect these sacred sites with reverence; never touch or disturb a pictograph.</p>
        <p>A special thank you to Michael Furtman, author of <a href="https://www.amazon.com/dp/0916691020/ref=cm_sw_r_tw_dp_U_x_F1qaEbFXRC8Z6" target="_blank">Magic on the Rocks Canoe Country Pictographs</a>, for facts and figures reference.</p>
        <p><em>These images were captured without the usage of flash photography.</em></p>
    </section>

    <section v-if="sectionName === 'portage'">
        <h4>Portaging</h4>
        <p>For hundreds of years, portaging from lake to lake is how travellers moved between lakes throughout the Boundary Waters. A portage is a land connection between two bodies of water and is sure to be a part of your Boundary Waters journey. </p>
        <p>Although some people enjoy kayaking or stand-up paddleboarding in the Boundary Waters, canoeing is the most popular mode of transportation as canoes are built for portaging overhead.</p>
        <div class="cols col3">
            <div class="col">
                <div class="responsiveImage tall" v-lazy:background-image="`/data/pics/portage/portage_01.jpg`"></div>
            </div>
            <div class="col">
                <div class="responsiveImage tall" v-lazy:background-image="`/data/pics/portage/portage_02.jpg`"></div>
            </div>
        </div>
        <p>The length of a portage is measured in “rods”. Each rod is 5.5 yards or 16.5 feet. The longest portage in the Boundary Waters is the Grand Portage from Lake Superior to the Pigeon River, which is 2,720 rods, or about 8.5 miles long.</p>
        <p>The portage you see here in this 360-degree photo is only 15 rods.</p>
    </section>

    <section v-if="sectionName === 'swim'" >
        <h4>Water Quality</h4>
        <p>The Boundary Waters is known for its many clear, freshwater lakes—we have the glaciers to thank for creating this enchanting scenery. It’s impossible to not want to dip your toes, or even a cup, in these velvety, wild waters.</p>
        <p>Swimming is one of the simplest joys in the Boundary Waters. The quiet, clean waters are a welcome gift on a hot day. Late summer is  the best time to take a dip as the waters will be warmer. Keep in mind, smaller lakes warm faster.</p>
        <div class="cols col3">
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/swimming/swimming_08.jpg`"></div>
            </div>
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/swimming/swimming_05.jpg`"></div>
            </div>
            <div class="col">
                <div class="responsiveImage" v-lazy:background-image="`/data/pics/swimming/swimming_03.jpg`"></div>
            </div>
        </div>
        <h4>Water Safety</h4>
        <p>While some Boundary Waters enthusiasts drink water right from the lake, most never do so directly from shore. Make sure to boil your water or use a filtration system or water purifying tablets before consumption.</p>
        <p>Water safety is crucial in the Boundary Waters, not just for drinking, but for paddling and swimming as well. Wearing a U.S. Coastguard-approved PFD has saved lives in the Wilderness. Also, a good pair of waterproof shoes can protect your feet while portaging and taking a dip in one of these cool, glacial lakes. </p>
    </section>

  </div>
</template>

<script>
export default {
  name: 'DetailContent',
  props: {
    sectionName: String
  }
}
</script>