<template>
  <div id="app" v-bind:class="showNav ? 'showNav':''">
    <transition
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut">
      <introScreen v-if="currentSection == 'home'" />
      <storySection v-if="!staticPages.includes(currentSection)" :key="currentSection" :targ="currentSection" ref="vidPlayer"/>
      <forTeachers v-if="currentSection == 'teachers'" />
      <getHelp v-if="currentSection == 'help'" />
      <endSection v-if="currentSection == 'action'" />
    </transition>
    <footer>
      <div class="where">
        <div class="wrapper">
          <a class="showNav" href="#" @click.prevent="toggleNav">
            <span v-if="!showNav">Where to?</span><span v-else>Hide Map</span>
          </a>
        </div>
      </div>
      <div v-if="currentSection != 'home' && currentSection != 'action'" class="next" @click="changeSection(nextSection)">
        <h4><span>{{nextSection}}</span><strong>NEXT</strong></h4>
      </div>
      <div class="showMobileNav" @click="toggleMobileNav">
        More
      </div>
      <nav v-bind:class="showMobileNav ? 'showNav':''">
        <img src="images/logo.svg" alt="" class="logo">
        <a href="#" @click.prevent="changeSection('help')">Help/Tips</a>
        <a href="https://www.savetheboundarywaters.org/the-threat">The Issue</a>
        <a href="https://www.savetheboundarywaters.org/about-campaign">The Campaign</a>
        <a href="#" @click.prevent="changeSection('teachers')">Field Trip</a>
        <a href="#" @click.prevent="changeSection('action')">Take Action</a>
      </nav>
    </footer>

    <transition
    enter-active-class="animated slideInUp"
    leave-active-class="animated slideOutDown">
    <div id="Map" v-if="showNav" style="animation-duration: 0.5s">
      <div class="navContent contentStyle">
        <h4>There's no shortage of activities in the Boundary Waters</h4>
        <h3>Where will the journey take you?</h3>
        
        <ul class="nav">
          <li v-for="section in mapSections" :key="section.section" 
            v-bind:class="[section.section, currentSection === section.section ? 'youAreHere':'']" 
            @click="changeSection(section.section)">
            <NavIcon :icon="section.section" />
            <span>{{section.title}}</span>
          </li>
        </ul>

      </div>
    </div>
    </transition>
  </div>
</template>

<style lang="scss">
.renderMe {
  width: 1;
}
</style>

<script>
import axios from 'axios'
import NavIcon from '@/components/navIcons.vue'
import introScreen from '@/views/Home.vue'
import endSection from '@/views/Action.vue'
import forTeachers from '@/views/Teachers.vue'
import storySection from '@/views/mapSection.vue'
import getHelp from '@/views/Help.vue'
import { isMobile, deviceDetect } from 'mobile-device-detect';

export default {
  components: {
    NavIcon, introScreen, endSection, storySection, forTeachers, getHelp
  },
  data(){
    return {
      showNav: false,
      showMobileNav: false,
      mapSections: [],
      currentSection: 'home',
      nextSection: null,
      videoIsPlaying: false,
      audioOn: true,
      currentSectionType:'',
      hasMotionPermission: false,
      mobile: isMobile ? true : false,
      deviceInfo: null,
      showTutorial: true,
      staticPages: [
        'home',
        'action',
        'help',
        'teachers'
      ]
    }
  },
  created(){
    this.fetchData();
    this.deviceInfo = deviceDetect();
    console.log(this.deviceInfo);

    setTimeout(function(){
        // This hides the address bar:
        window.scrollTo(0, 1);
    }, 0);
  },
  methods: {
    toggleNav: function(){
      this.showNav = !this.showNav;
      this.showMobileNav = false
    },
    toggleMobileNav: function(){
      this.showMobileNav = !this.showMobileNav
      this.showNav = false
    },
    fetchData: function(){
      var that = this;
      axios.get('/data/content.json?t=' + Date.now()).then(response => {
          that.mapSections = response.data.mapSections;
      })
    },
    changeSection: function(targ){
      this.showNav = false;
      this.showMobileNav = false;
      let that = this
      if (this.mobile && typeof DeviceMotionEvent.requestPermission === 'function') {
        DeviceMotionEvent.requestPermission()
          .then(response => {
            if (response == 'granted') {
              window.addEventListener('devicemotion', () => {})
              that.hasMotionPermission = true
            }
          })
          .catch(console.error)

        DeviceOrientationEvent.requestPermission()
          .then(response => {
            if (response == 'granted') {
              window.addEventListener('deviceorientation', () => {})
            }
          })
          .catch(console.error)
      }
      if(this.currentSection != targ){
        // this.$ua.trackView(this.currentSection)
        this.currentSection = targ
        let i = this.mapSections.findIndex(sec => sec.section === this.currentSection);
        if(i > 0){
          this.currentSectionType = this.mapSections[i].type
        } else {
          this.currentSectionType = 'static'
        }
        if(i + 1 != this.mapSections.length){
          this.nextSection = this.mapSections[i + 1].section
        } else {
          this.nextSection = null
        }
      }
      
      this.showNav = false;
    },
    toggleAudio: function(){
      if(this.audioOn){
        this.$refs.vidPlayer.muteAudio()
        this.audioOn = false
      } else {
        this.$refs.vidPlayer.unmuteAudio()
        this.audioOn = true
      }
    },
    toggleVideo: function(){
      if(this.videoIsPlaying){
        this.$refs.vidPlayer.pauseVideo()
      } else {
        this.$refs.vidPlayer.playVideo()
      }
    }
  }
}
</script>