import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
//import router from './router'
import store from './store'
import VueLazyload from 'vue-lazyload'
import VueAnalytics from 'vue-ua'

Vue.config.productionTip = false

Vue.use(VueLazyload)
Vue.use(VueAnalytics, {
    appName: 'BWCA360',
    appVersion: '2.0',
    trackingId: 'UA-54473540-3',
    debug: true,
    trackPage: true,
})

new Vue({
    // router,
    store,
    VueLazyload,
    VueAnalytics,
    render: function(h) { return h(App) }
}).$mount('#app')