<template>
  <div class="getHelp mapSection">
    <img src="images/logo.svg" alt="" id="logo">
    <div class="actionContent">

        <h5>Getting Started</h5>
        <p>Boundarywaters360.com utilizes modern web technologies and 360 degree panoramic video to simulate a Boundary Waters experience. This site is known to work on desktop computers using Chrome, Edge &amp; Firefox browsers. It also works on many mobile devices including recent iPhones, iPads and Android phones.</p>

        <h5>Optimal Experience</h5>
        <p>To get the best experience, we recommend accessing this site using Safari on an iPad running iPadOS 13 or later with a fast wifi internet connection. In this configuration you can interact with the videos by moving the iPad around to reveal more of the experience.</p>
    
        <h5>Navigating Scenes</h5>
        <p>Most mobile devices (phones, tablets, etc) contain sensors that allow you to interact with 360 content by moving your device. In most cases this will work either by default or after you “Allow Motion Access”. However on devices running iOS 10, 11 or 12 you must enable motion controls manually. In these cases go to the <em>Home Screen</em> -> <em>Settings</em> -> <em>Safari</em> -> <em>Privacy &amp; Security</em> -> enable <em>Motion &amp; Orientation Access</em></p>

        <h5>Tip: Don’t stare at the ground</h5>
        <p>When loading experiences using motion controls, it is important to hold your device perpendicular to the ground so that your device's sensors match the horizon in the videos. This will keep you from looking at the ground (or water) when the video loads.</p>
        <div class="buttonWrap">
          <a class="nav" href="#" @click.prevent="getStarted('paddle')"><i class="fas fa-backpack"></i> Explore the BWCA</a>
        </div>
    </div>

  </div>
</template>

<script>

import vue100vh from 'vue-100vh'

export default {
  name: 'getHelp',
  components: {
    vue100vh
  },
  data(){
    return {
      
    }
  },
  methods: {
    getStarted: function(targ){
      this.$parent.changeSection(targ)
    }
  },
  mounted () {
      this.$ua.trackView('Get Help', true)
    }
}
</script>
