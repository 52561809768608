<template>
  <div class="actionStep mapSection">
    <img src="images/logo.svg" alt="" id="logo">
    <div class="actionContent">
        <h2>Help us protect this place.</h2>
        <h3>Tomorrow could be too late.</h3>
        <p>If you enjoyed this digital experience, please help protect the real thing: This 1.1 million acre Wilderness is currently endangered by proposed sulfide-ore copper mining—the most toxic industry in America.</p>
        <p>Take action to protect the Boundary Waters!</p>
         <div class="buttonWrap">
           <a class="button" href="https://www.savetheboundarywaters.org/action">TAKE ACTION</a>
         </div>
    </div>

  </div>
</template>

<script>

import vue100vh from 'vue-100vh'

export default {
  name: 'actionStep',
  components: {
    vue100vh
  },
  data(){
    return {
      
    }
  },
  methods: {
    
  },
  mounted () {
      this.$ua.trackView('Take Action', true)
    }
}
</script>
