<template>
  <div class="home mapSection">
    <div class="spacer"></div>
    <vueVimeoPlayer class="video-bg" ref="vPlayer" :options="videoOptions" :video-id="videoID" :loop="true" @loaded="onReady" @progress="onPlaying"></vueVimeoPlayer>
    <div class="homeLoading" v-bind:class="videoReady ? 'videoReady' : ''"></div>
    <img src="images/logo.svg" alt="" id="logo">
    <div class="mid">
      <div class="sota">
        <img src="images/sota.svg" alt="">
      </div>
      <h2><span>Visit</span><span class="special"><span>the</span></span><span>BWCA</span></h2>
      <h3>Experience a Journey Through MN's<br />1.1 Million Acre Wildlands</h3>
      <button class="button" @click="getStarted('paddle')">Grab a Paddle</button>
    </div>
    <div class="bottom">
      <p>*mosquitoes not included in experience - headphones recommended</p>
    </div>
  </div>
</template>

<script>
import { vueVimeoPlayer } from 'vue-vimeo-player'
import vue100vh from 'vue-100vh'

export default {
  name: 'home',
  components: {
    vueVimeoPlayer, vue100vh
  },
  data(){
    return {
      videoID: '361093685',
      videoOptions: {
        controls: false,
        background: true,
      },
      videoReady: false,
      firstPlay: false,
    }
  },
  methods: {
    onReady(){
            // this.videoPlayer = this.$refs.vPlayer;
            // this.videoPlayer.mute();
            // this.videoPlayer.play();
        },
    onPlaying: function(){
        if(!this.firstPlay){
            this.firstPlay = true
            this.videoReady = true
        }
    },
    getStarted: function(targ){
      this.$parent.changeSection(targ)
    }
  }
  ,
  mounted () {
      this.$ua.trackView('Welcome', true)
    }
}
</script>
