<template>
  <div class="forTeachers mapSection">
    <img src="images/logo.svg" alt="" id="logo">
    <div class="actionContent">
        <h2>Take a Field Trip</h2>
        <p>Go on a virtual field trip to the Boundary Waters Canoe Area Wilderness! Download this set of questions, then explore the Boundary Waters 360 scenes and "i" (information buttons) to help complete the worksheet and learn about the Wilderness.</p>
        <div class="buttonWrap">
          <a class="nav" href="data/360Worksheet.pdf" download="360Worksheet.pdf"><i class="fas fa-file-pdf"></i> Download Questions</a>
          <a class="nav" href="#" @click.prevent="getStarted('paddle')"><i class="fas fa-backpack"></i> Explore the BWCA</a>
        </div>
    </div>

  </div>
</template>

<script>

import vue100vh from 'vue-100vh'

export default {
  name: 'forTeachers',
  components: {
    vue100vh
  },
  data(){
    return {
      
    }
  },
  methods: {
    getStarted: function(targ){
      this.$parent.changeSection(targ)
    }
  },
  mounted () {
      this.$ua.trackView('For teachers', true)
    }
}
</script>
